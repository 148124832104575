import {
  BrowserConfig,
  DestinationPlugin,
  Event,
  Result
} from '@amplitude/analytics-types';

const ecommerceEvents = new Set([
  'Product List Viewed',
  'Products Searched',
  'Product Clicked',
  'Product Added',
  'Product Added to Wishlist',
  'Product Removed',
  'Product Viewed',
  'Cart Viewed',
  'Checkout Started',
  'Checkout Step Viewed',
  'Checkout Step Completed',
  'Payment Info Entered',
  'Order Completed',
  'Order Updated',
  'Order Refunded',
  'Order Cancelled',
  'Clicked Promotion',
  'Viewed Promotion'
]);

export class ZarazPlugin implements DestinationPlugin {
  name = 'zaraz-plugin';
  type = 'destination' as const;
  config?: BrowserConfig;

  get set() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any)?.zaraz?.set || this.localLog;
  }

  get track() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any)?.zaraz?.track || this.localLog;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private removeArrayProp(obj: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newProps: any = {};

    for (const key in obj) {
      if (!key.includes('.[].')) {
        newProps[key] = obj[key];
      }
    }
    return newProps;
  }

  get ecommerce() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any)?.zaraz?.ecommerce || this.localLog;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private localLog(...args: any[]) {
    // eslint-disable-next-line no-console
    console.log('Zaraz (Local):', ...args);
  }

  /**
   * setup() is called on plugin installation
   * example: client.add(new MyDestinationPlugin());
   */
  async setup(config: BrowserConfig) {
    this.config = config;
  }

  private sampleReturn(event: Event) {
    return {
      code: 200,
      event: event,
      message: 'skipped'
    };
  }

  private setUserId(event: Event) {
    const id = event.user_properties?.$set?.id as string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any)?.zaraz?.set?.('userId', id);
  }

  /**
   * execute() is called on each event instrumented
   * example: client.track('New Event');
   */
  async execute(event: Event): Promise<Result> {
    const { event_type, event_properties } = event;
    const properties = this.removeArrayProp(event_properties);

    if (event_type === '$identify') {
      this.setUserId(event);
    }

    ecommerceEvents.has(event_type)
      ? this.ecommerce(event_type, properties)
      : this.track(event_type, properties);

    return this.sampleReturn(event);
  }
}
