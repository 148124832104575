import {
  BrowserConfig,
  EnrichmentPlugin,
  Event
} from '@amplitude/analytics-types';

export class FlattyPlugin implements EnrichmentPlugin {
  name = 'flatty-plugin';
  type = 'enrichment' as const;
  config?: BrowserConfig;

  /**
   * setup() is called on plugin installation
   * example: client.add(new AddEventIdPlugin());
   */
  async setup(config: BrowserConfig) {
    this.config = config;
  }

  /**
   * execute() is called on each event instrumented
   * example: client.track('New Event');
   */
  async execute(event: Event): Promise<Event> {
    const { event_properties } = event;

    if (!event_properties || Object.keys(event_properties).length === 0) {
      return event;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = { ...event_properties };

    for (const [prop, value] of Object.entries(event_properties)) {
      if (!Array.isArray(value) || !value[0]) {
        continue;
      }

      const keys = Object.keys(value[0]);

      for (const key of keys) {
        result[`${prop}.[].${key}`] = value.map(v => v[key]);
      }
    }

    event.event_properties = {
      ...result
    };

    return event;
  }
}
